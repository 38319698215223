import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  item: null,
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  createTaxRate({ commit }, item) {
    return this.$axios.$post('admin/taxRates/create', { ...item }).then((response) => {
      commit('add', { ...response.data })
      return response.data
    })
  },
  removeTaxRate({ commit }, item) {
    return this.$axios.$delete(`admin/taxRates/${item.id}`).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  updateTaxRate({ commit }, item) {
    return this.$axios.$put(`admin/taxRates/${item.id}`, { ...item }).then((response) => {
      commit('update', { ...response.data })
      return response.data
    })
  },
  fetchTaxRate({ commit }, id) {
    return this.$axios.$get(`admin/taxRates/${id}`).then((response) => {
      return response.data
    })
  },
  fetchTaxRates({ commit }) {
    return this.$axios.$get('admin/taxRates').then((response) => {
      commit('load', response.data)
      return response.data
    })
  }
}
