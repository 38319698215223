<template>
  <div v-if="(item.media && item.media['100w']) || item.color">
    <img v-if="item.media" :src="item.media['100w'].src" :width="size" :height="size" />

    <div
      v-if="item.color"
      :style="{
        backgroundColor: item.color,
        width: `${size}px`,
        height: `${size}px`
      }"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'Thumbnail',
  props: {
    item: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: '48'
    }
  }
}
</script>
