import Vue from 'vue'
import moment from 'moment/moment'

Vue.filter('money', (str, lang) => {
  return formatPrice(str, lang)
})

Vue.filter('currency', (obj, currency, field = null) => {
  if (field && obj && Object.keys(obj).includes(field)) {
    return formatPrice(obj[field], currency)
  }

  if (obj && obj.prices && obj.prices.includes((x) => x.currency === currency)) {
    return formatPrice(obj.prices.find((x) => x.currency === currency)[field], currency)
  }

  if (obj && obj.price) {
    return formatPrice(obj.price, currency)
  }

  return formatPrice(obj, currency)
})

Vue.filter('datetime', (str) => {
  if (!str) return ''
  return moment(str)
    .utcOffset(str, false)
    .format('D MMM YYYY [at] HH:mm')
})

const formatPrice = (price, currency) => {
  if (currency === 'EUR') {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    }).format(price || 0)
  } else if (currency === 'USD') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price || 0)
  } else {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(price || 0)
  }
}
