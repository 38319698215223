import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const actions = {
  updateCollectionProduct({ commit }, item) {
    return this.$axios.$put(`admin/collectionProducts/${item.id}`, { ...item }).then((response) => {
      commit('update', { ...response.data })
    })
  }
}
