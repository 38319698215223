<template>
  <Popover
    ref="popover"
    v-bind="$attrs"
    :position="position"
    :content-position="contentPosition"
    :container-class="containerClass"
    @show="onShow"
    @hide="$emit('hide')"
  >
    <template #default="{ open }">
      <slot v-bind="{ selected }" name="selected">
        <div
          :class="[
            {
              'text-gray-700 bg-white border-gray-300 hover:border-black transition rounded-lg': theme === 'light',
              'text-white bg-transparent border-transparent rounded-lg': theme === 'dark',
              'text-gray-700 bg-transparent border-transparent rounded-r': theme === 'no-border',
              'w-36': !noLimitWidth && theme !== 'no-border'
            }
          ]"
          class="border border-solid py-1 px-2 h-10 flex items-center"
          :style="selected || selectedItems.length > 1 ? 'background-color: #D9D9D9 !important;' : ''"
          @click="open"
        >
          <span
            v-if="!selected || selectedItems.length"
            class="flex-grow text-black truncate"
            v-text="selectedItems.length >= 1 ? `${placeholder} (${selectedItems.length})` : placeholder"
          />
          <span v-else class="flex flex-grow items-center justify-start">
            <span v-if="selected.icon" :class="[iconSet, `fa-${selected.icon}`, 'mr-2']" />
            <img
              v-if="selected.image"
              :src="selected.image"
              :alt="$t('ApiMultipleSelect.image', { label: selected.label })"
              class="h-3 mr-1 w-4"
            />
            <span style="white-space: nowrap;">
              {{ selected.label | truncateText(theme === 'no-border' ? 5 : 12) }}
            </span>
          </span>
          <i :class="[iconSet, `fa-${icon}`]" class="text-xs flex-shrink-0 ml-2" />
        </div>
      </slot>
    </template>
    <template #content="{open, close}">
      <div class="text-gray-700">
        <div v-if="searchable" class="p-1">
          <Input
            ref="input"
            v-model="query"
            :placeholder="$t('ApiMultipleSelect.search')"
            :icon="loading ? 'spinner-third fa-spin' : 'search'"
            type="text"
            autofocus
            @focus="open"
            @blur="close"
            @keyup.esc="close"
            @keydown.enter="search"
            @keyup="debouncedSearch"
          />
        </div>
        <div :style="{ maxHeight: '450px', minWidth: '150px' }" class="overflow-auto mt-1 px-1">
          <div
            v-if="allowEmpty"
            class="trans-hover py-2 px-3 hover:bg-gray-200 rounded focus:outline-none truncate cursor-pointer"
            @click="select({ value: null, label: placeholder })"
          >
            {{ placeholder }}
          </div>
          <div
            v-for="(option, index) in displayedOptions"
            :key="index"
            class="trans-hover flex items-center py-2 px-3 hover:bg-gray-100 focus:outline-none truncate cursor-pointer relative"
            :class="searchMultiSelect ? 'multi-select-item' : 'single-select-item'"
            :style="
              (selectedItems.length && selectedItems.some((item) => item === option.value)) ||
              (selected && selected.value === option.value)
                ? 'background-color: #D9D9D9;'
                : ''
            "
            @click="searchMultiSelect ? multiSelect(option) : select(option)"
          >
            <slot v-bind="{ option, index }" name="option">
              <Checkbox
                v-if="searchMultiSelect"
                :value="selectedItems.some((item) => item === option.value)"
                @click="multiSelect(option)"
              />
              <Icon v-if="option.icon" :name="option.icon" :set="iconSet" />
              <img
                v-if="option.image"
                :src="option.image"
                :alt="$t('ApiMultipleSelect.image', { label: option.label })"
                class="w-4 h-3 mr-2 object-cover"
              />
              <span :class="{ 'ml-2': option.icon }">
                {{ option.label }}
              </span>
            </slot>
          </div>

          <div
            v-if="!filterOptions || !filterOptions.length"
            class="flex justify-center mt-1 py-2 text-gray-500 text-sm"
          >
            {{ $t('messages.no_result') }}
          </div>
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from '@/components/Popover'
import Input from '@/components/Input'

export default {
  components: {
    Popover,
    Input
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Array],
      default: null
    },
    position: {
      type: String,
      default: 'bottom'
    },
    contentPosition: {
      type: String,
      default: 'left'
    },
    iconSet: {
      type: String,
      default: 'fas'
    },
    icon: {
      type: String,
      default: 'chevron-down'
    },
    searchable: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: 'light'
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    containerClass: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: null
    },
    noLimitWidth: {
      type: Boolean,
      default: false
    },
    searchMultiSelect: {
      type: Boolean,
      default: false
    },
    filterValue: {
      type: String,
      default: null
    },
    filterValues: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      query: '',
      selectedItems: [],
      debouncedSearch: null,
      filterOptions: [],
      selectedOptions: []
    }
  },
  computed: {
    selected() {
      return this.item
        ? this.item
        : this.value
        ? this.filterOptions.find((x) => {
            // eslint-disable-next-line eqeqeq
            return x ? x.value == this.value : undefined
          })
        : null
    },
    setFilterOptions() {
      return this.filterOptions.filter(
        (option) => !this.selectedOptions.some((selected) => selected.value === option.value)
      )
    },
    displayedOptions() {
      return this.selectedOptions.length ? [...this.selectedOptions, ...this.setFilterOptions] : this.filterOptions
    }
  },
  created() {
    this.debouncedSearch = this.debounce(this.search, 300)
  },
  async mounted() {
    this.setOptions()
    await this.filterValues.forEach((filter) => {
      if (filter.value === this.filterValue && filter.initialFetch) {
        this.searchFilter('', filter.value)
      }
    })
  },
  methods: {
    setOptions(options) {
      if (this.options.length) this.filterOptions = this.options
      else this.filterOptions = options
    },
    debounce(func, delay) {
      let timeoutId
      return function(...args) {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
          func(...args)
        }, delay)
      }
    },
    search(event) {
      this.searchFilter(event.target.value, this.filterValue)
    },
    select(option) {
      this.$emit('input', option.value)
      this.$emit('select', option)

      if (this.$refs.popover && this.closeOnSelect) {
        this.$refs.popover.close()
      }
    },
    multiSelect(option) {
      if (this.selectedItems.some((item) => item === option.value)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== option.value)
        this.selectedOptions = this.selectedOptions.filter((o) => o.value !== option.value)
      } else {
        this.selectedItems.push(option.value)
        this.selectedOptions.push(option)
      }

      this.$emit('select', this.selectedItems)
    },

    async searchFilter(query, value) {
      const response = await this.$axios
        .$get('/admin/' + value, {
          params: {
            name: query
          }
        })
        .then((response) => {
          if (response.data.length > 0 && response.data[0].name && response.data[0].id) {
            const transformedArray = response.data.map((item) => ({
              value: item.id,
              label: item.name
            }))

            return transformedArray
          }
          return response.data
        })

      this.setOptions(response)
    },
    clear() {
      this.$emit('input', null)

      if (this.$refs.popover && this.closeOnSelect) {
        this.$refs.popover.close()
      }
    },
    clearSelectedItems() {
      this.selectedItems = []
      this.$emit('input', this.selectedItems)
    },
    onShow(e) {
      this.$emit('show', e)
      if (this.searchable && this.$refs.input) {
        this.$refs.input.focus()
      }
    }
  }
}
</script>
<style>
.single-select-item:first-child::after {
  content: 'Default';
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);

  background-color: rgb(189, 189, 254);
  color: black;

  border-radius: 5px;
  padding: 2px 5px;
  font-size: 11px;
}

.rounded-r {
  border-radius: 0 0.25rem 0.25rem 0;
}
</style>
