<template>
  <div
    :class="{
      'z-50 is-focused': focused,
      static: !focused,
      [containerClass]: !!containerClass,
      'is-disabled': disabled
    }"
    v-bind="$attrs"
    tabindex="0"
    class="popover relative focus:outline-none"
    @focus="open"
    @blur="popoverClose"
  >
    <div class="popover-trigger p-0 bg-transparent" v-on="$listeners">
      <slot v-bind="{ open, close }" />
    </div>
    <div
      ref="content"
      :class="[position, notchClass, contentWrapperClass]"
      class="popover-content shadow rounded bg-white min-w-full mb-4"
    >
      <slot v-bind="{ open, close }" name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'bottom'
    },
    contentPosition: {
      type: String,
      default: 'left'
    },
    contentWrapperClass: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: null
    },
    listenContainer: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    notchClass() {
      if (this.position === 'top' || this.position === 'bottom') {
        return 'horizontal horizontal-' + this.contentPosition
      }
      return 'vertical vertical-' + this.contentPosition
    }
  },
  methods: {
    popoverClose(e) {
      // If the focus is moving to the content, don't close the popover
      if (e && e.relatedTarget && e.type === 'blur' && this.$refs.content.contains(e.relatedTarget)) {
        return
      }

      this.close()
    },
    close() {
      this.focused = false
      this.$emit('hide')
    },
    open() {
      if (this.disabled) {
        return
      }
      this.focused = true
      this.$emit('show')
    }
  }
}
</script>

<style lang="scss">
$notch-size: 12px;

.popover {
  position: relative;

  &.is-disabled {
    opacity: 0.7;
  }

  &-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    min-width: 100%;
    padding: calc(#{$notch-size} - calc(#{$notch-size} / 3));
    transition-property: visibility, opacity;
    transition-duration: 300ms;
    z-index: 9999;

    &::before {
      content: '';
      width: $notch-size;
      height: $notch-size;
      background-color: theme('colors.white');
      border-color: rgba(0, 0, 0, 0.06);
      border-style: solid;
      position: absolute;
    }

    &.top {
      top: 0;
      transform: translateY(-100%);

      &::before {
        top: 100%;
        transform: translateY(-50%) rotate(45deg);
        border-right-width: 1px;
        border-bottom-width: 1px;
      }
    }

    &.bottom {
      top: 100%;

      &::before {
        top: 0;
        transform: translateY(-50%) rotate(45deg);
        border-top-width: 1px;
        border-left-width: 1px;
      }
    }

    &.left {
      left: 0;
      transform: translateX(-100%);

      &::before {
        left: 100%;
        transform: translateX(-50%) rotate(45deg);
        border-top-width: 1px;
        border-right-width: 1px;
      }
    }

    &.right {
      left: 100%;

      &::before {
        left: 0;
        transform: translateX(-50%) rotate(45deg);
        border-left-width: 1px;
        border-bottom-width: 1px;
      }
    }

    &.horizontal.top {
      margin-top: -$notch-size;
    }

    &.horizontal.bottom {
      margin-top: $notch-size;
    }

    &.vertical.right {
      margin-left: $notch-size;
    }

    &.vertical.left {
      margin-left: -$notch-size;
    }

    // Notch positioning
    &.horizontal {
      &-center {
        left: 50%;
        transform: translateX(-50%);

        &.top {
          transform: translateX(-50%) translateY(-100%);
        }

        &::before {
          left: calc(50% - #{$notch-size} / 2);
        }
      }

      &-right {
        right: 0;

        &::before {
          right: $notch-size;
        }
      }

      &-left {
        &::before {
          left: $notch-size;
        }
      }
    }

    &.vertical {
      &-center {
        top: 50%;
        transform: translateY(-50%);

        &.left {
          transform: translateX(-100%) translateY(-50%);
        }

        &::before {
          top: calc(50% - #{$notch-size} / 2);
        }
      }

      &-top {
        top: 0;

        &::before {
          top: $notch-size;
        }
      }

      &-bottom {
        bottom: 0;

        &::before {
          bottom: $notch-size;
        }
      }
    }
  }

  &.is-focused {
    > .popover-content {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
