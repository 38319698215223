<template>
  <label :class="disabled ? 'cursor-default' : 'cursor-pointer'" class="custom-checkbox flex mb-0" v-bind="$attrs">
    <input v-bind="$attrs" :checked="value" :disabled="disabled" type="checkbox" v-on="inputListeners" />
    <div
      :class="[{ danger, outline }, rounded ? 'rounded-full' : 'rounded']"
      class="checkmark w-4 h-4 flex my-1 items-center justify-center mr-3 flex-shrink-0"
    >
      <Icon :name="icon" class="mark" fixed />
    </div>
    <div class="checkbox-label">
      <template v-if="label">
        {{ label }}
      </template>
      <template v-else>
        <slot />
      </template>
    </div>
  </label>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'check'
    },
    label: {
      type: String,
      default: ''
    },
    danger: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputListeners() {
      const self = this
      return {
        ...this.$listeners,
        input(e) {
          self.$emit('input', e.target.checked)
        }
      }
    }
  }
}
</script>
<style lang="scss">
.custom-checkbox {
  input[type='checkbox'] {
    appearance: none;
  }

  .checkmark {
    flex-shrink: 0;
    flex-grow: 0;
    background-color: white;
    border: 1px solid black;
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;

    &.outline {
      border: 2px solid theme('colors.primary.light');
      background-color: transparent;

      &.danger {
        border: 2px solid theme('colors.red.600');
      }
    }
  }

  .checkbox-label {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover .checkmark {
    background-color: theme('colors.gray.200');

    &.outline {
      background-color: theme('colors.gray.200');
    }
  }

  .checkmark > .mark {
    color: theme('colors.black');
    opacity: 0;
    transition: opacity 250ms;
    transition-timing-function: ease-in-out;
    font-size: 8px;
  }

  input[type='checkbox']:checked + .checkmark {
    &.danger {
      background-color: theme('colors.red.600');
    }

    .mark {
      opacity: 1;
    }
  }

  input[type='checkbox']:disabled ~ .checkmark {
    background-color: theme('colors.gray.200');

    &.outline {
      border: 2px solid theme('colors.gray.300');
    }
  }

  input[type='checkbox']:checked:disabled + .checkmark {
    background-color: theme('colors.gray.300');

    &.danger {
      background-color: theme('colors.red.300');

      &.outline {
        background-color: transparent;
      }
    }

    .mark {
      opacity: 1;
    }

    &.outline {
      .mark {
        color: theme('colors.gray.300');
      }
    }
  }

  input[type='checkbox']:disabled ~ .checkbox-label {
    color: theme('colors.gray.400');
  }
}
</style>
