import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  addTag({ commit }, item) {
    return this.$axios.$post('admin/tags/create', { ...item }).then((response) => {
      commit('add', { ...response.data })
      return response.data
    })
  },
  removeTag({ commit }, item) {
    return this.$axios.$delete(`admin/tags/${item.id}`).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  updateTag({ commit }, item) {
    return this.$axios.$put(`admin/tags/${item.id}`, { ...item }).then((response) => {
      commit('update', { ...response.data })
    })
  },
  fetchTags({ commit }) {
    return this.$axios.$get('admin/tags').then((response) => {
      commit('load', response.data)
    })
  }
}
