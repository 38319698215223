<template>
  <div v-frag>
    <Loading v-show="loading" :message="$t('messages.loading')" />
    <form v-show="!loading" v-bind="$attrs" @submit.prevent="onSubmit">
      <slot />
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FormSubmitNoenter from '@/mixins/form-submit-noenter.js'

export default {
  mixins: [FormSubmitNoenter],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errors: {}
    }
  },
  methods: {
    ...mapActions('notification', ['addNotification']),
    add(key, error) {
      this.errors[key] = error
    },
    empty() {
      return Object.keys(this.errors).length === 0
    },
    has(key) {
      return this.errors && Object.keys(this.errors).includes(key)
    },
    error(key) {
      return this.has(key) ? this.errors[key] : null
    },
    prefixErrors(prefix) {
      return Object.keys(this.errors).reduce((acc, key) => {
        if (key.startsWith(prefix)) {
          acc[key] = this.errors[key]
        }
        return acc
      }, {})
    },
    remove(key) {
      if (this.has(key)) {
        delete this.errors[key]
      }
    },
    clear() {
      this.errors = {}
    },
    parse(error, notify = true) {
      this.clear()

      let message = 'Ooppps! Something wrong.'
      if (error && error.response) {
        const { data: response } = error.response
        if (response.errors) {
          Object.keys(response.errors).forEach((key) => {
            const error = Array.isArray(response.errors[key]) ? response.errors[key][0] : response.errors[key]
            this.add(key, error)
          })
        }

        if (response.message) {
          message = response.message
        }
      }

      if (notify) {
        if (this.empty()) {
          this.addNotification({
            message,
            type: 'error',
            duration: 3000
          })
        } else {
          Object.keys(this.errors).forEach((key) => {
            this.addNotification({
              message: this.errors[key],
              type: 'error',
              duration: 3000
            })
          })
        }
      }
    },
    onSubmit(e) {
      this.clear()
      this.$emit('submit', e)
    }
  }
}
</script>
