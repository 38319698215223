import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  addReview({ commit }, item) {
    return this.$axios.$post('admin/reviews/create', { ...item }).then((response) => {
      commit('add', { ...response.data })
    })
  },
  removeReview({ commit }, item) {
    return this.$axios.$delete(`admin/reviews/${item.id}`).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  updateReview({ commit }, item) {
    return this.$axios.$put(`admin/reviews/${item.id}`, { ...item }).then((response) => {
      commit('update', { ...response.data })
    })
  },
  fetchReview({ commit }, id) {
    return this.$axios.$get(`admin/reviews/${id}`).then((response) => {
      return response.data
    })
  },
  fetchReviews({ commit }) {
    return this.$axios.$get('admin/reviews').then((response) => {
      commit('load', response.data)
    })
  }
}
