import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  item: null,
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  createUser({ commit }, item) {
    return this.$axios.$post('admin/users/create', { ...item }).then((response) => {
      commit('add', { ...response.data })
    })
  },
  removeUser({ commit }, item) {
    return this.$axios.$delete(`admin/users/${item.id}`).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  updateUser({ commit }, item) {
    return this.$axios.$put(`admin/users/${item.id}`, { ...item }).then((response) => {
      commit('update', { ...response.data })
    })
  },
  fetchUser({ commit }, id) {
    return this.$axios.$get(`admin/users/${id}`).then((response) => {
      return response.data
    })
  },
  fetchUsers({ commit }, query) {
    const params = {}
    if (query) {
      params.search = query
    }

    return this.$axios.$get('admin/users', { params }).then((response) => {
      commit('load', response.data)
      return response.data
    })
  }
}
