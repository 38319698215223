var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outline-none",class:{
    'relative z-50': _vm.isActive,
    'static z-0': !_vm.isActive
  },attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;if($event.target !== $event.currentTarget)return null;$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"static inline-block outline-none",on:{"click":_vm.open}},[_vm._t("default")],2),_vm._v(" "),_c('div',{class:[
      'trans-fade',
      'flex items-center justify-center',
      'w-screen h-screen',
      'overflow-auto',
      'fixed top-0 left-0',
      {
        'visible opacity-100': _vm.isActive,
        'invisible opacity-0': !_vm.isActive
      }
    ]},[_c('div',{staticClass:"fixed top-0 left-0 w-full h-full bg-black opacity-50 z-0",on:{"click":_vm.close}}),_vm._v(" "),_c('div',{staticClass:"relative w-full bg-white shadow rounded shadow-lg z-10",class:[`max-w-${_vm.size}`]},[_c('div',{staticClass:"flex w-full justify-center items-center pt-4 px-4"},[_c('div',{staticClass:"flex-grow font-bold text-xl text-gray-700"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"ml-auto flex-shrink-0"},[_c('Button',{staticClass:"text-gray-600 hover:text-gray-700 hover:bg-gray-100 w-6 h-6 p-0 justify-center",attrs:{"color":"empty","size":"empty","icon":"times","icon-set":"fas"},on:{"click":_vm.close}})],1)]),_vm._v(" "),_c('div',{class:[`p-${_vm.padding}`, 'text-gray-800']},[_vm._t("content",null,null,{ open: _vm.open, close: _vm.close, toggle: _vm.toggle })],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }