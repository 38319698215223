<template>
  <div class="flex justify-center items-center w-full h-full p-4">
    <Icon name="spinner-third" set="fad" class="fa-spin text-2xl text-gray-500" />
    <div v-if="message" class="ml-3 text-lg text-gray-600" v-text="message" />
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: null
    }
  }
}
</script>
