import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  createNewsletter({ commit }, item) {
    return this.$axios.$post('admin/emailNewsletters/create', { ...item }).then((response) => {
      commit('add', { ...response.data })
      return response.data
    })
  },
  removeNewsletter({ commit }, item) {
    return this.$axios.$delete(`admin/emailNewsletters/${item.id}`).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  updateNewsletter({ commit }, item) {
    return this.$axios.$put(`admin/emailNewsletters/${item.id}`, { ...item }).then((response) => {
      commit('update', { ...response.data })
    })
  },
  fetchNewsletter({ commit }, id) {
    return this.$axios.$get(`admin/emailNewsletters/${id}`).then((response) => {
      return response.data
    })
  },
  fetchNewsletters({ commit }) {
    return this.$axios.$get('admin/emailNewsletters').then((response) => {
      commit('load', response.data)
    })
  }
}
