import { CRUDMutations } from '@/utils/store-utilities'
/*
import {
  CONDITION_TYPE,
  CONDITION_COLUMN,
  CONDITION_RELATION
} from '@/utils/enums'
*/

const defaultItem = (item) => ({
  id: null,
  parent_id: null,
  media_id: null,
  title: null,
  slug: null,
  type: null,
  match_all_conditions: null,
  conditions: [],
  ...item
})

const defaultLinks = (links) => ({
  next: null,
  prev: null,
  first: null,
  last: null,
  ...links
})

export const state = () => ({
  items: [],
  editing: {},
  links: {
    next: null,
    prev: null,
    first: null,
    last: null
  }
})

export const mutations = {
  ...CRUDMutations(defaultItem),
  setLinks(state, links) {
    state.links = {
      ...state.links,
      ...links
    }
  }
}

export const getters = {}

export const actions = {
  async getCollection({ commit }, id) {
    const item = await this.$axios.$get(`admin/collections/${id}`)

    commit('set', item.data)
  },
  async createCollection({ commit }, collection) {
    const created = await this.$axios.$post('admin/collections/create', {
      ...defaultItem(collection)
    })

    commit('add', defaultItem(created.data))
    return created
  },
  async updateCollection({ commit }, collection) {
    const update = await this.$axios.$put(`admin/collections/${collection.id}`, {
      ...defaultItem(collection)
    })

    commit('update', defaultItem(update.data))
    return update
  },
  async removeCollection({ commit }, collection) {
    const remove = await this.$axios.$delete(`admin/collections/${collection.id}`)

    if (remove.data) {
      commit('remove', collection)
    }
    return remove
  },
  async fetchCollections({ commit }, params = {}) {
    const list = await this.$axios.$get('admin/collections', { params })
    if (list && list.data && list.data.length) {
      commit('load', list.data.map(defaultItem))
      commit('setLinks', defaultLinks(list.links))
      return
    }

    commit('clear')
    commit('setLinks', defaultLinks({}))
  },
  fetchCollection({ commit }, id) {
    return this.$axios.$get(`admin/collections/${id}`).then((response) => {
      return response.data
    })
  }
}
