<template>
  <div v-bind="$attrs" :class="{ 'has-error': error }">
    <div class="flex flex-row justify-between w-full">
      <div
        v-if="label || $scopedSlots.label"
        class="mb-2 text-gray-600 font-semibold uppercase text-xs flex-1"
        @click="isCollapsed = !isCollapsed"
      >
        <slot name="label">
          {{ label }}
        </slot>

        <div v-if="tooltip" v-tippy :content="tooltip" role="button" class="text-xs text-gray-500 inline-block">
          <i class="fas fa-question-circle" />
        </div>
      </div>
      <div v-if="collapsable">
        <button
          v-if="collapsable"
          type="button"
          class="mb-2 flex justify-center text-xs items-center p-0 pb-2"
          @click="isCollapsed = !isCollapsed"
        >
          <Icon :name="isCollapsed ? 'chevron-up' : 'chevron-down'" class="collapse-icon text-gray-700 w-2 h-2" />
        </button>
      </div>
    </div>
    <div v-show="!collapsable || isCollapsed" :class="[slotClass, { 'flex-row': horizontal }]">
      <slot />
    </div>
    <div v-if="collapsable && !isCollapsed" class="border-t border-gray-200 w-full my-4"></div>
    <div v-if="error" class="mt-1 text-red-500 text-xs" v-text="error" />
    <div v-if="message" class="text-xs p-1">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { isComponent } from '../utils/component'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    slotClass: {
      type: String,
      default: ''
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapsed: this.collapsable ? this.collapsed : false
    }
  },
  computed: {
    error() {
      const form = this.findForm(this.$parent)
      if (this.name && form) {
        return form.error(this.name)
      }

      return null
    }
  },
  methods: {
    findForm(vm) {
      if (isComponent(vm, '<Form>')) {
        return vm
      }

      return vm.$parent ? this.findForm(vm.$parent) : null
    }
  }
}
</script>

<style lang="scss">
.has-error {
  input,
  .border {
    border-color: theme('colors.red.500') !important;
  }
}

.hide-this {
  display: none;
}
</style>
