<template>
  <div :class="{ shadow: showShadows }" class="dc-panel bg-white rounded-sm">
    <div :class="{ 'pb-4': title || (collapsable && isCollapsed) }" class="flex w-full items-center px-4 pt-4">
      <button
        v-if="collapsable && title"
        type="button"
        class="text-lg flex-grow text-left text-gray-700"
        @click="isCollapsed = !isCollapsed"
      >
        {{ title }}
      </button>

      <h1 v-else-if="!collapsable && title" class="text-lg flex-shrink-0 text-gray-700">
        {{ title }}
      </h1>

      <slot name="header" />

      <div v-if="$slots && $slots.title" class="ml-auto w-1/2">
        <slot name="title" />
      </div>

      <Button
        v-if="collapsable"
        class="flex justify-center items-center ml-2"
        theme="empty"
        @click="isCollapsed = !isCollapsed"
      >
        <Icon :class="{ 'is-collapsed': isCollapsed }" name="chevron-up" class="collapse-icon text-gray-700" />
      </Button>
    </div>
    <div v-show="collapsable ? !isCollapsed : true" class="pb-4 px-4 overflow-x-auto" style="overflow-y: hidden;">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    collapsable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFlat: false,
      isCollapsed: this.collapsed
    }
  },
  computed: {
    showShadows() {
      return !this.isFlat && !!this.flat
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse-icon {
  transition-property: transform;
  transition-duration: 200ms;

  &.is-collapsed {
    transform: rotate(180deg);
  }
}
</style>
