import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  createShippingProfile({ commit }, item) {
    return this.$axios.$post('admin/shippingProfiles/create', { ...item }).then((response) => {
      commit('add', { ...response.data })
    })
  },
  removeShippingProfile({ commit }, item) {
    return this.$axios.$delete(`admin/shippingProfiles/${item.id}`).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  updateShippingProfile({ commit }, item) {
    return this.$axios.$put(`admin/shippingProfiles/${item.id}`, { ...item }).then((response) => {
      commit('update', { ...response.data })
    })
  },
  fetchShippingProfile({ commit }, id) {
    return this.$axios.$get(`admin/shippingProfiles/${id}`).then((response) => {
      return response.data
    })
  },
  fetchShippingProfiles({ commit }) {
    return this.$axios.$get('admin/shippingProfiles').then((response) => {
      commit('load', response.data)
    })
  }
}
