<template>
  <div
    :class="[color, `text-${size}`, 'inline-flex justify-center px-3 py-2 rounded w-20 pointer-events-none']"
    v-bind="$attrs"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'bg-gray-300'
    },
    size: {
      type: String,
      default: 'xs'
    }
  }
}
</script>
