<template>
  <textarea
    ref="textarea"
    :value="value"
    class="min-h-textarea w-full resize-none border border-solid border-gray-300 rounded p-2 bg-transparent focus:outline-none"
    v-bind="$attrs"
    @input="change"
  ></textarea>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.setSize()
  },
  methods: {
    change(e) {
      this.setSize()
      this.$emit('input', e.target.value)
    },
    setSize() {
      this.$refs.textarea.style.height = 0
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight + 10}px`
    }
  }
}
</script>
