import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  addProductLabel({ commit }, item) {
    return this.$axios.$post('admin/productLabels/create', { ...item }).then((response) => {
      commit('add', { ...response.data })
    })
  },
  removeProductLabel({ commit }, item) {
    return this.$axios.$delete(`admin/productLabels/${item.id}`).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  removeWithoutDeleteProductLabel({ commit }, item) {
    return this.$axios.$post(`admin/productLabels/${item.id}`, { media_id: item.media_id }).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  updateProductLabel({ commit }, item) {
    return this.$axios.$put(`admin/productLabels/${item.id}`, { ...item }).then((response) => {
      commit('update', { ...response.data })
    })
  },
  fetchProductLabels({ commit }) {
    return this.$axios.$get('admin/productLabels').then((response) => {
      commit('load', response.data)
    })
  },
  fetchProductLabel({ commit }, id) {
    return this.$axios.$get(`admin/productLabels/${id}`)
  }
}
