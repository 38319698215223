var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g({staticClass:"trans-hover flex items-center focus:outline-none whitespace-no-wrap",class:[
    _vm.hover,
    _vm.size && `text-${_vm.size}`,
    _vm.padding,
    {
      rounded: !_vm.square,
      'opacity-50 cursor-default pointer-events-none': _vm.disabled
    }
  ],attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":_vm.click}},_vm.$listeners),[(_vm.icon && _vm.iconPosition === 'left')?_c('div',{staticClass:"flex items-center justify-center",class:{ 'mr-2': _vm.$slots.default }},[_c('Icon',{attrs:{"name":_vm.icon,"set":_vm.iconSet,"fixed":""}})],1):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.icon && _vm.iconPosition === 'right')?_c('div',{staticClass:"flex items-center justify-center",class:{ 'ml-2': _vm.$slots.default }},[_c('Icon',{attrs:{"name":_vm.icon,"set":_vm.iconSet,"fixed":""}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }