var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center border rounded border-solid w-full border-gray-300",class:[
    {
      'bg-gray-100': _vm.readOnly,
      'bg-gray-200': _vm.disabled,
      'bg-white': !_vm.readOnly && !_vm.disabled
    }
  ],style:(_vm.error ? 'border: 1px solid #c20202 !important' : _vm.theme.input)},[_vm._t("left"),_vm._v(" "),(_vm.icon)?_c('div',{staticClass:"flex items-center flex-shrink-0",style:((_vm.theme.icon, 'padding-left: 1rem;'))},[_c('i',{staticClass:"text-md",class:[`fa-${_vm.icon}`, _vm.iconSet]})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-grow"},[_c('input',_vm._g(_vm._b({directives:[{name:"debounce",rawName:"v-debounce",value:((val) => _vm.$emit('debounce', val)),expression:"(val) => $emit('debounce', val)"}],ref:"input",staticClass:"w-full p-2 bg-transparent focus:outline-none h-10 text-sm",attrs:{"disabled":_vm.disabled,"readOnly":_vm.readOnly}},'input',_vm.$attrs,false),_vm.inputListeners))]),_vm._v(" "),_vm._t("right")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }