import { render, staticRenderFns } from "./Panel.vue?vue&type=template&id=5aca9baa&scoped=true"
import script from "./Panel.vue?vue&type=script&lang=js"
export * from "./Panel.vue?vue&type=script&lang=js"
import style0 from "./Panel.vue?vue&type=style&index=0&id=5aca9baa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aca9baa",
  null
  
)

export default component.exports